<template>
    <div class="container_success">
        <div class="success_img">
            <img src="../../assets/paySuccess.png" />
        </div>
        <div class="success_title">报名成功</div>
        <div class="success_text">
            <div>活动主题：{{dataList.unionActivity.activity_title}}</div>
            <div>活动时间：{{dataList.unionActivity.start_time}}-{{dataList.unionActivity.end_time}}</div>
        </div>
        <div class="success_btn" @click="details">查看报名详情</div>
    </div>
</template>
<script>
import {getActiveDeatil_api} from "@/api/deal"
export default {
  data() {
    return {
      queryList:'',
      dataList:{}
    };
  },
  created() {
    document.title = "报名成功";
    this.queryList = this.$route.query;
    this.getList();
  },
  methods: {
    getList() {
      getActiveDeatil_api(this.queryList.id).then(res=>{
        if(res.code == 0){
          console.log(res);
          this.dataList = res.data
        }
      })
    },
    details(){
      console.log(1)
      this.$router.push(`./activityDetails?id=${this.queryList.id}`)
    }
  }
};
</script>
<style>
.container_success {
  padding-top: 402px;
  box-sizing: border-box;
  text-align: center;
}
.success_img {
  width: 180px;
  height: 180px;
  margin: 0 auto 27px;
}
.success_img img {
  width: 100%;
  height: 100%;
}
.success_title {
  font-size: 40px;
  color: #07c160;
  margin-bottom: 79px;
}
.success_text div {
  font-size: 32px;
  color: #101010;
  margin-bottom: 20px;
}
.success_btn {
  width: 410px;
  height: 90px;
  background: #07c160;
  border: 2px solid #07c160;
  opacity: 1;
  border-radius: 15px;
  text-align: center;
  line-height:90px;font-size: 34px;
  color: #fff;
  margin: 150px auto 0;
}
</style>
